// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-more-js": () => import("./../../../src/pages/learn-more.js" /* webpackChunkName: "component---src-pages-learn-more-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-rankings-js": () => import("./../../../src/pages/rankings.js" /* webpackChunkName: "component---src-pages-rankings-js" */),
  "component---src-templates-ranking-js": () => import("./../../../src/templates/ranking.js" /* webpackChunkName: "component---src-templates-ranking-js" */),
  "component---src-templates-web-details-js": () => import("./../../../src/templates/webDetails.js" /* webpackChunkName: "component---src-templates-web-details-js" */)
}

